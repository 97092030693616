import request from '@/util/request'
import {
	Message,
} from 'element-ui'


export default{
	//登录
	login(data){
		return request({url: '/manager/login/doLogin',method: 'post',data})		
	},	
	//登出
	logout(data){
		return request({url: '/manager/login/logout',method: 'post',data})		
	},	
	//修改密码
	setMannerPassword(data){
		return request({url: 'manager/user/setPassword',method: 'post',data})		
	},	
	//谷歌验证检查
	validateGcode(data){
		return request({url: '/manager/login/validateGcode',method: 'post',data})		
	},
	//谷歌验证码绑定
	bingGoogle(data){
		return request({url: '/manager/login/bindGoogle',method: 'post',data})		
	},
	//新增代理
	addAgent(data){
		return request({url: '/manager/member/addAgent',method: 'post',data})		
	},
	//代理列表
	agentList(data){
		return request({url: '/manager/member/agent',method: 'post',data,allowRepetition:true})		
	},
	//修改代理
	editAgent(data){
		return request({url: '/manager/member/editAgent',method: 'post',data})		
	},
	//重置代理密码
	setAgentPassword(data){
		return request({url: '/manager/member/setAgentPassword',method: 'post',data})		
	},
	
	//新增商家
	addTrade(data){
		return request({url: '/manager/member/addTrader',method: 'post',data})		
	},
	//商家列表
	traderList(data){
		return request({url: '/manager/member/trader',method: 'post',data,allowRepetition:true})		
	},
	//修改商家
	editTrader(data){
		return request({url: '/manager/member/editTrader',method: 'post',data})		
	},
	//重置商家密码
	setTraderPassword(data){
		return request({url: '/manager/member/setTraderPassword',method: 'post',data})		
	},
	//商家账号启用/停用
	traderToggle(data){
		return request({url: '/manager/member/traderToggle',method: 'post',data})		
	},
	//商家 设置代付密码
	setTraderPayPassword(data){
		return request({url: '/manager/member/setTraderPayPassword',method: 'post',data})		
	},
	//商家 冻结/解冻余额
	setAmount(data){
		return request({url: '/manager/member/setAmount',method: 'post',data})		
	},
	//商家 手续费列表
	traderFeeList(data){
		return request({url: '/manager/member/traderFeeList',method: 'post',data})
	},
	//商家 手续费列表
	traderLimitList(data){
		return request({url: '/manager/member/traderGoldList',method: 'post',data})		
	},
	//商家 设置手续费
	setTraderFee(data){
		return request({url: '/manager/member/setTraderFee',method: 'post',data})		
	},
	//商家 设置单笔限额
	setTraderGold(data){
		return request({url: '/manager/member/setTraderGold',method: 'post',data})		
	},
	//代理 手续费列表
	agentFeeList(data){
		return request({url: '/manager/member/agentFeeList',method: 'post',data})		
	},
	//代理 手续费列表
	agentTraderFeeList(data){
		return request({url: '/manager/member/agentTraderFeeList',method: 'post',data})		
	},	
	//代理 设置手续费
	setAgentFee(data){
		return request({url: '/manager/member/setAgentFee',method: 'post',data})		
	},
	//商家 支付权限配置列表
	traderPayList(data){
		return request({url: '/manager/member/traderPayList',method: 'post',data})		
	},
	//商家 支付权限配置列表
	setTraderPayWay(data){
		return request({url: '/manager/member/setTraderPayWay',method: 'post',data})		
	},
	//码商 搬砖工数列表
	carderAll(data){
		return request({url: '/manager/carder/all',method: 'post',data})		
	},
	//码商 搬砖工数列表
	carderDelete(data){
		return request({url: '/manager/carder/delete',method: 'post',data})		
	},
	//码商 搬砖工详情
	carderInfo(data){
		return request({url: '/manager/carder/info',method: 'post',data})		
	},
	//码商 上级代理
	showAgent(data){
		return request({url: '/manager/carder/showAgent',method: 'post',data})		
	},
	//码商 充值 / 扣款
	carderSetAmount(data){
		return request({url: '/manager/carder/setAmount',method: 'post',data})		
	},
	//码商 开工/停工
	setState(data){
		return request({url: '/manager/carder/setState',method: 'post',data})		
	},
	//码商 重置密码
	setPassword(data){
		return request({url: '/manager/carder/setPassword',method: 'post',data})		
	},
	//码商 重置密码
	emptyMoney(data){
		return request({url: '/manager/carder/emptyMoney',method: 'post',data})		
	},
	//码商 重置密码
	setAdvanceMoney(data){
		return request({url: '/manager/carder/setAdvanceMoney',method: 'post',data})		
	},
	//码商 重置密码
	setGoogleKey(data){
		return request({url: '/manager/carder/setGoogleKey',method: 'post',data})		
	},
	
	//商家充值谷歌验证码
	setMemberGoogleKey(data){
		return request({url: 'manager/member/setGoogleKey',method: 'post',data,allowRepetition:true})		
	},
	
	//通道管理 支付 代理银行卡监控 代理树
	treeList(data){
		return request({url: '/manager/carder/treeList',method: 'post',data})		
	},
	//通道管理 支付 代理银行卡监控 银行卡列表 carder_id 码商ID  business_no:商家号查询
	cardList(data){
		return request({url: '/manager/card/cardList',method: 'post',data,allowRepetition:true})		
	},
	//通道管理 支付 代理银行卡监控 获取标签
	cardTags(data){
		return request({url: '/manager/card/tags',method: 'post',data})		
	},
	//通道管理 支付 代理银行卡监控 设置标签
	setTag(data){
		return request({url: '/manager/card/setTag',method: 'post',data})		
	},
	//通道管理 支付 代理银行卡监控 离线 1在线  0离线
	setOnline(data){
		return request({url: '/manager/card/setOnline',method: 'post',data})		
	},
	//通道管理 支付 代理银行卡监控 状态修改   0启用  1停用
	cardToggle(data){
		return request({url: '/manager/card/cardToggle',method: 'post',data})		
	},
	//通道管理 支付 代理银行卡监控 删除银行卡
	carddelete(data){
		return request({url: '/manager/card/delete',method: 'post',data})		
	},
	//通道管理 支付 代理银行卡监控 连续失败次数 清零统计
	cardcleared(data){
		return request({url: '/manager/card/cleared',method: 'post',data})		
	},
	//银行卡流水  进账
	moneyList(data){
		return request({url: '/manager/card/inList',method: 'post',data})		
	},
	//银行卡出账
	outList(data){
		return request({url: '/manager/card/outList',method: 'post',data,allowRepetition:true})		
	},
	//卡账单
	cardBill(data){
		return request({url: '/manager/card/bill',method: 'post',data})		
	},
	//通道管理 支付 代理银行卡监控    总计
	countAll(data){
		return request({url: '/manager/carder/countAll',method: 'post',data})		
	},
	
	//订单管理 通道列表  hasRepay  1所有通道 2 补单可用通道
	paywayAll(data){
		return request({url: '/manager/pay_type/all',method: 'post',data,allowRepetition:true})		
	},
	//订单管理 订单状态
	stateList(data){
		return request({url: '/manager/order/stateList',method: 'post',data,allowRepetition:true})		
	},
	//订单管理 交易订单列表
	orderAllList(data){
		return request({url: '/manager/order/allList',method: 'post',data,allowRepetition:true})		
	},
	//订单管理 交易订单 通知商家
	noticeIn(data){
		return request({url: '/manager/order/noticeIn',method: 'post',data})		
	},
	//订单管理 代付订单 通知商家
	noticeOut(data){
		return request({url: '/manager/order/noticeOut',method: 'post',data})		
	},
	//代理进账报表
	reportAgent(data){
		return request({url: '/manager/report/agent',method: 'post',data})	
	},
	//商家进账报表
	reportTrader(data){
		return request({url: '/manager/report/trader',method: 'post',data})		
	},
	//商家出账报表
	reportTraderOut(data){
		return request({url: '/manager/report/traderOut',method: 'post',data})		
	},
	//商家费率报表
	reportTraderFee(data){
		return request({url: 'manager/report/traderFee',method: 'post',data})		
	},
	//通道报表
	reportPayType(data){
		return request({url: '/manager/report/payType',method: 'post',data})		
	},
	//通道管理 支付类型管理
	payTypeAll(data){
		return request({url: '/manager/pay_type/all',method: 'post',data,allowRepetition:true})		
	},
	//通道管理 支付类型管理
	payTypeSetState(data){
		return request({url: '/manager/pay_type/setState',method: 'post',data})		
	},
	//通道管理 支付类型管理
	payTypeSetFee(data){
		return request({url: '/manager/pay_type/setFee',method: 'post',data})		
	},
	//通道管理 商家绑卡 商家列表
	traderCard(data){
		return request({url: '/manager/trader_card/trader',method: 'post',data})		
	},
	//通道管理 商家绑卡 商家已绑卡列表
	traderCardCard(data){
		return request({url: '/manager/trader_card/card',method: 'post',data})		
	},
	//通道管理 商家绑卡  码商列表
	carderList(data){
		return request({url: '/manager/trader_card/carderList',method: 'post',data})		
	},
	//通道管理 商家绑卡  绑卡
	bindCard(data){
		return request({url: '/manager/trader_card/bindCard',method: 'post',data})		
	},
	//通道管理 商家绑卡  商家已绑定卡 总计
	cardCount(data){
		return request({url: '/manager/trader_card/cardCount',method: 'post',data})		
	},
	//通道管理 商家绑卡  商家已绑定卡 总计
	orderCountAll(data){
		return request({url: '/manager/order/countAll',method: 'post',data,allowRepetition:true})		
	},
	//通道管理 商家综合管理   银行卡列表
	bankList(data){
		return request({url: '/manager/card/bank',method: 'post',data})		
	},
	//通道管理 商家综合管理   数据列表
	traderCardList(data){
		return request({url: '/manager/trader_card/cardList',method: 'post',data})		
	},
	//通道管理 商家综合管理   统计数据
	traderCardcountAll(data){
		return request({url: '/manager/trader_card/countAll',method: 'post',data})		
	},
	//通道管理 商家综合管理   设置限额
	setTraderConfig(data){
		return request({url: '/manager/trader_card/setTraderConfig',method: 'post',data})		
	},
	//通道管理 卡处理   银行卡列表
	cardDealBankList(data){
		return request({url: '/manager/card_deal/allList',method: 'post',data})		
	},
	//通道管理 卡处理   银行卡列表
	cardDealCountAll(data){
		return request({url: '/manager/card_deal/countAll',method: 'post',data})
	},
	//通道管理 卡处理   银行卡列表
	cardDealstate(data){
		return request({url: '/manager/card_deal/state',method: 'post',data})		
	},
	//通道管理 卡处理   银行卡列表
	cardDealsSetState(data){
		return request({url: '/manager/card_deal/setState',method: 'post',data})		
	},
	//通道管理 银行卡开通设置  银行卡列表
	bankAllList(data){
		return request({url: '/manager/bank/allList',method: 'post',data})		
	},
	//通道管理 银行卡开通设置  设置
	banksetValue(data){
		return request({url: '/manager/bank/setValue',method: 'post',data})		
	},
	//订单管理 代付订单   订单列表
	orderOutList(data){
		return request({url: '/manager/order/outList',method: 'post',data,allowRepetition:true})		
	},
	//订单管理 交易订单   代付
	replenishmentState(data){
		return request({url: '/manager/order/replenishmentState',method: 'post',data,allowRepetition:true})		
	},
	//订单管理 交易订单   代付
	/* 
	     $id = input('id');
	         $state = input('state');
	         $amount = input('amount');
	         $bill_id = input('bill_id');//账单ID
			 补单接口加了个账单ID，从账单这补得时候传下，我这会把订单交易号关联到账单上
			 */
	replenishment(data){
		return request({url: '/manager/order/replenishment',method: 'post',data})		
	},
	//订单管理 补单统计
	replenishmentCountAll(data){
		return request({url: '/manager/order/replenishmentCountAll',method: 'post',data,allowRepetition:true})		
	},
	//订单管理 代付订单   代付订单状态
	outStateList(data){
		return request({url: '/manager/order/outStateList',method: 'post',data,allowRepetition:true})		
	},
	//银行卡弹框内容  
	/* $id = input('id');//码商ID | 银行卡ID
        $type = input('type');//1-码商 2-银行卡 */
	infoTotal(data){
		return request({url: '/manager/carder/infoTotal',method: 'post',data})		
	},
	//新增码商
	carderAdd(data){
		return request({url: '/manager/carder/add',method: 'post',data})		
	},
	//码商费率列表
	carderFeeList(data){
		return request({url: '/manager/carder/feeList',method: 'post',data})		
	},
	//码商费率列表
	setCarderFee(data){
		return request({url: '/manager/carder/setFee',method: 'post',data})		
	},
	//交易订单  撤单
	ordercancel(data){
		return request({url: '/manager/order/cancel',method: 'post',data})		
	},
	//代付订单  主订单冲正
	orderRush(data){
		return request({url: '/manager/order/rush',method: 'post',data})		
	},
	//代付订单  重新派卡
	disCard(data){
		return request({url: '/manager/order/disCard',method: 'post',data})		
	},
	//代付订单 关闭子订单
	closeOrder(data){
		return request({url: '/manager/order/close',method: 'post',data})		
	},
	//代付订单 关闭主订单
	closeMainOrder(data){
		return request({url: '/manager/order/closeMain',method: 'post',data})		
	},
	//代付订单 主订单确认   
	// manager/order/ensureMain 代付主订单确认接口  参数 id
	// state = 2 | state = 7 显示此按钮
	ensureMain(data){
		return request({url: '/manager/order/ensureMain',method: 'post',data})		
	},
	
	//代付订单 子订单确认
	ensureOrder(data){
		return request({url: '/manager/order/ensure',method: 'post',data})		
	},
	//代付订单  子订单冲正
	rushCard(data){
		return request({url: '/manager/order/rushCard',method: 'post',data})		
	},
	
	/* 
	 manager/order/repayReplenishment 代付子订单（补订单确认）接口  参数  id note备注
	 state=7 显示此按钮
	 */
	repayReplenishment(data){
		return request({url: '/manager/order/repayReplenishment',method: 'post',data})		
	},
	//提现订单 审核
	checkMentionOrder(data){
		return request({url: '/manager/order/checkMentionOrder',method: 'post',data})		
	},
	//交易订单通知
	inNotice(data){
		return request({url: '/manager/order/inNotice',method: 'post',data})		
	},
	//交易订单通知 备注
	setInNotice(data){
		return request({url: '/manager/order/setInNotice',method: 'post',data})		
	},
	//代付订单通知
	outNotice(data){
		return request({url: '/manager/order/outNotice',method: 'post',data})		
	},
	//代付订单通知 备注
	setOutNotice(data){
		return request({url: '/manager/order/setOutNotice',method: 'post',data})		
	},
	//通道管理 支付 代理银行卡监控 设置银行卡信息
	/* 
	 $id = input('card_id');
	 $card_no = input('card_no');//卡号
	 $telephone = input('telephone');//手机号
	 $eve_used_money = input('eve_used_money');//每日收款上限金额
	 $eve_used_num = input('eve_used_num');//每日收款上限笔数
	 $keep_gold = input('keep_gold');//余额留存上限
	 
	 $pay_state = input('pay_state');//充值是否启用 0-是 1-否
	 $repay_state = input('repay_state');//代付是否启用 0-是 1-否
	 */
	setInfo(data){
		return request({url: '/manager/card/setInfo',method: 'post',data})		
	},
	//补单
	addOrder(data){
		return request({url: '/manager/order/addOrder',method: 'post',data})		
	},
	//搬砖工日志
	/* 
	 $page = input('page', 1);
	 $size = input('size', Config::get('paginate.list_rows'));
	 $id = input('id');
	 $type = input('type', 0); //1-充值|扣款 2-清空额度 3-手续费
	 */
	logList(data){
		return request({url: '/manager/carder/logList',method: 'post',data})		
	},
	/* 商家操作日志
	 /**
	 public function logList()
	 {
	 $page = input('page', 1);
	 $size = input('size', Config::get('paginate.list_rows'));
	 $id = input('id');
	 $type = input('type', 0); //1-冻结|解冻 2-限额 3-手续费*/
	memberlogList(data){
		return request({url: '/manager/member/logList',method: 'post',data})		
	},
	//系统统计数据
	systemCountAll(data){
		return request({url: '/manager/system/countAll',method: 'post',data,allowRepetition:true})		
	},
	
	//系统统计数据
	outInfo(data){
		return request({url: '/manager/order/outInfo',method: 'post',data,allowRepetition:true})		
	},
	//白名单
	//$id = input('id');
    // $login_ip = input('login_ip');
    // $repay_ip = input('repay_ip');
	setWhitelist(data){
		return request({url: '/manager/member/setWhitelist',method: 'post',data})		
	},
	diffMoneyList(data){
		return request({url: '/manager/card/diffMoneyList',method: 'post',data})		
	},
	//代付主订单锁定解锁
	// $id = input('id');
	// $type = input('type');//1-认领 2-取消
	owner(data){
		return request({url: '/manager/order/owner',method: 'post',data})		
	},
	//监控失败统计
	monitorErrorCount(data){
		return request({url: '/manager/card/monitorErrorCount',method: 'post',data})		
	},
	
	//http://api.jxkspys.com/manager/card/manualDisCardList 获取手动派卡列表的接口
	// $business_no = input('business_no');
	// $carder_id = input('carder_id');
	// $min_gold = input('min_gold', 0);
	// $max_gold = input('max_gold', 10000000);
	// $card = input('card');
	// $card_name = input('card_name');
	// $page = input('page', 1);
	// $size = input('size', Config::get('paginate.list_rows'));
	manualDisCardList(data){
		return request({url: '/manager/card/manualDisCardList',method: 'post',data})		
	},
	//  子订单手动派卡
    // {
    //     $id = input('id');
    //     $data = input('data');// money  bank_card_id
	manualDisCard(data){
		return request({url: '/manager/order/manualDisCard',method: 'post',data})		
	},
	//  主订单手动派卡
    // {
    //     $id = input('id');
    //     $data = input('data');// money  bank_card_id
	manualDisCardMain(data){
		return request({url: '/manager/order/manualDisCardMain',method: 'post',data})		
	},
	//平台提润
	  // $name = input('name');//持卡人姓名
	  //   $card = input('card');//卡号
	  //   $bank = input('bank');//银行名称
	  //   $money = input('money');//金额
	  //   $code = input('code');//谷歌验证
	mentionMoney(data){
		return request({url: '/manager/system/mentionMoney',method: 'post',data})		
	},
	
	//平台用户详情 包含可提现金额 money字段
	
	mentionInfo(data){
		return request({url: '/manager/system/info',method: 'post',data})		
	},
	//平台提润列表   type == 4
	
	mentionoutList(data){
		return request({url: '/manager/order/outList',method: 'post',data})		
	},
	
	//锁卡 解锁 银行卡 
	  //$id = input('id');
	  //$type = input('type'); //1-锁卡 2-解锁
	  
	  // $id = input('id');//卡ID
	  //         $order_id = input('order_id');
	  //         $water_no = input('water_no');
	  //         $card_order_id = input('card_order_id');
	  //         $type = input('type'); //1-锁卡 2-解锁
	discardLock(data){
		return request({url: '/manager/card/discardLock',method: 'post',data})		
	},
	
	
	//卡处理 时限设置	  
	  // $id = input('id');
	  // $time = input('time');
	carDealSetTime(data){
		return request({url: '/manager/card_deal/setTime',method: 'post',data})		
	},

	//卡处理 时限设置	  
	  // $id = input('id');
	  // $time = input('time');
	carderEdit(data){
		return request({url: '/manager/carder/edit',method: 'post',data})		
	},
	//已选卡列表查询
	// $page = input('page', 1);
	//         $size = input('size', Config::get('paginate.list_rows'));
	//         $order_id = input('order_id');
	//         $water_no = input('water_no');
	//         $card_order_id = input('card_order_id');
	choosedDisCardList(data){
		return request({url: '/manager/card/choosedDisCardList',method: 'post',data})		
	},
	//搬砖工报表
	carderReport(data){
		return request({url: '/manager/report/carder',method: 'post',data})		
	},
	//商户设置匹配
	setTraderMatch(data){
		return request({url: '/manager/member/setTraderMatch',method: 'post',data})		
	},
	//用户管理
	/**
	     * 获取所有账号.
	     * @return \think\response\Json|void
	     * @throws \think\exception\DbException
	     */
	  /*  public function getUsers()
	    {
	        $size = input('size', Config::get('paginate.list_rows'));  // 每页多少条.
	        $page = input('page', 0); // 第几页. */
	
	getUsers(data){
		return request({url: '/manager/user/getUsers',method: 'post',data})		
	},
	/**
	     * 新增账号.
	     * @return \think\response\Json|void
	     * @throws \think\db\exception\DataNotFoundException
	     * @throws \think\db\exception\ModelNotFoundException
	     * @throws \think\exception\DbException
	     */
	   /* public function addUser(){
	        $username = trim(input('user')); // 用户名.
	        $password = strtolower(trim(input('password'))); // 密码. 前端限定再次输入密码. */
			
	addUser(data){
		return request({url: '/manager/user/addUser',method: 'post',data})		
	},
	/**
	     * 重设账号密码.
	     * @return \think\response\Json|void
	     * @throws \think\db\exception\DataNotFoundException
	     * @throws \think\db\exception\ModelNotFoundException
	     * @throws \think\exception\DbException
	     */
   /* public function resetUserPassword()
	{
		$username = trim(input('username'));  // 用户名称.
		$password = trim(input('password')); // 密码. */
		resetUserPassword(data){
			return request({url: '/manager/user/resetUserPassword',method: 'post',data})		
		},
	/**
	     * 重设账号谷歌验证.
	     * @return \think\response\Json|void
	     * @throws \think\db\exception\DataNotFoundException
	     * @throws \think\db\exception\ModelNotFoundException
	     * @throws \think\exception\DbException
	     */
	  /*  public function setUserGoogleKey()
	    {
	        $username = trim(input('username'));  // 用户名称. */
		setUserGoogleKey(data){
			return request({url: '/manager/user/setUserGoogleKey',method: 'post',data})		
		},
		/**
		     * 删除账号.
		     * @return \think\response\Json|void
		     */
	   /* public function delUser()
		{
			$id = input('id', 0); // 账号id.
			$username = input('username', ''); // 账号名称. */
		delUser(data){
			return request({url: '/manager/user/delUser',method: 'post',data})		
		},
		/**
		     * 获取角色.
		     * @return \think\response\Json|void
		     * @throws \think\exception\DbException
		     */
	   /* public function getRoles()
    {
		$size = input('size', Config::get('paginate.list_rows'));*/
		getRoles(data){
			return request({url: '/manager/user/getRoles',method: 'post',data})		
		},
		
		/**
		     * 给用户分配角色.
		     * @return \think\response\Json|void
		     * @throws \think\db\exception\DataNotFoundException
		     * @throws \think\db\exception\ModelNotFoundException
		     * @throws \think\exception\DbException
		     */
		/* public function configUserRole()
		{
			$manager_id = input('manager_id'); // 用户账号id.
			$role_id = input('role_id'); // 角色id. */
			configUserRole(data){
				return request({url: '/manager/user/configUserRole',method: 'post',data})		
			},
			
			/**
			     * 获取权限.
			     * @return \think\response\Json|void
			     * @throws \think\exception\DbException
			     */
			// public function getPermissions()
			// {
			//     $size = input('size', Config::get('paginate.list_rows')); // size 单页多少个.
			getPermissions(data){
				return request({url: '/manager/user/getPermissions',method: 'post',data})		
			},
			/**
			 * 给角色分配权限.
			 * @return \think\response\Json|void
			 * @throws \think\db\exception\DataNotFoundException
			 * @throws \think\db\exception\ModelNotFoundException
			 * @throws \think\exception\DbException
			 */
			// public function configRolePermission()
			// {
			//     $role_id = input('role_id'); // 角色id.
			//     $permission_id = input('permission_id'); // 权限id.
			//     $dealType = input('deal_type', '');  // 0 删除  1  选中.
			configRolePermission(data){
				return request({url: '/manager/user/configRolePermission',method: 'post',data})		
			},
			/**
			     * 删除角色.
			     * @return \think\response\Json|void
			     */
		   /* public function delRole()
			{
				$id = input('id', 0); // 角色id.
				$name = input('name', ''); // 角色名称. */
		delRole(data){
			return request({url: '/manager/user/delRole',method: 'post',data})		
		},
		/**
		     * 新增角色.
		     * @return \think\response\Json|void
		     * @throws \think\db\exception\DataNotFoundException
		     * @throws \think\db\exception\ModelNotFoundException
		     * @throws \think\exception\DbException
		     */
		/* public function addRole()
		{
			$name = trim(input('name', '')); // 角色名称. */
		addRole(data){
			return request({url: '/manager/user/addRole',method: 'post',data})		
		},
		//卡账号管理 获取单条卡信息
		cardinfo(data){
			return request({url: '/manager/card/info',method: 'post',data})		
		},
		//不可以卡
		
		manualDisCardUnavailableList(data){
			return request({url: '/manager/card/manualDisCardUnavailableList',method: 'post',data})		
		},
		//根据卡查失败的订单
		/*  public function getFailOrder()
			{
				$card = input('card'); */
		getFailOrder(data){
			return request({url: '/manager/card/getFailOrder',method: 'post',data})		
		},
		/**
		     * 银行卡报损
		     * @return \think\response\Json|void
		     * @throws \think\db\exception\DataNotFoundException
		     * @throws \think\db\exception\ModelNotFoundException
		     * @throws \think\exception\DbException
		     */
/* 		public function addBankCardLoss()
		{
			$type = input('type');//类型 1-码商扣除 2-平台扣除
			$bank_card_id = input('bank_card_id');//卡ID
			$money = input('money');//金额
			$note = input('note');//备注
 */		
		addBankCardLoss(data){
			return request({url: '/manager/card/addBankCardLoss',method: 'post',data})
		},
		/**
		        * 报损报表
		          * @return \think\response\Json|void
		          * @throws \think\exception\DbException
		          */
		   /* public function bankCardLossList()
		         {
		             $page = input('page', 1);
		             $size = input('size', Config::get('paginate.list_rows'));
		             $carder_id = input('carder_id');//码商ID
		             $type = input('type');//类型 1-码商扣除 2-平台扣除
		     
		     时间参数 startdate enddate
 */		
		bankCardLossList(data){
			return request({url: '/manager/card/bankCardLossList',method: 'post',data})
		},
		/**
		     * 银行卡报损撤销
		     * @return \think\response\Json|void
		     * @throws \think\db\exception\DataNotFoundException
		     * @throws \think\db\exception\ModelNotFoundException
		     * @throws \think\exception\DbException
		     */
		  /*  public function revokeBankCardLoss()
		    {
		        $id = input('id'); */
		revokeBankCardLoss(data){
			return request({url: '/manager/card/revokeBankCardLoss',method: 'post',data})
		},
		
	/**
	     * 手动充值&代付 停用|启用
	     * @return \think\response\Json|void
	     * @throws \think\db\exception\DataNotFoundException
	     * @throws \think\db\exception\ModelNotFoundException
	     * @throws \think\exception\DbException
	     */
	 /*   public function setManualState()
	    {
	        $state = input('state');//0-停用 1-启用
	        $id = input('carder_id'); */
	setManualState(data){
		return request({url: '/manager/carder/setManualState',method: 'post',data})
	},
	
	/**
	     * 商家余额留存上限
	     * @return \think\response\Json|void
	     * @throws \think\db\exception\DataNotFoundException
	     * @throws \think\db\exception\ModelNotFoundException
	     * @throws \think\exception\DbException
	     */
	/*    public function setTraderKeepGold()
	    {
	        $id = input('id');
	        $amount = input('amount'); */
			
	setTraderKeepGold(data){
		return request({url: '/manager/member/setTraderKeepGold',method: 'post',data})
	},
	//数据总览
	total(data){
		return request({url: '/manager/report/total',method: 'post',data})
	},
	
	//通道管理 支付宝管理  carder_id 码商ID  business_no:商家号查询
	alipayall(data){
		return request({url: '/manager/alipay/all',method: 'post',data,allowRepetition:true})		
	},
	
	//通道管理 支付 代理银行卡监控 状态修改   0启用  1停用
	alipayToggle(data){
		return request({url: '/manager/alipay/alipayToggle',method: 'post',data})		
	},
	
	//卡账号管理 获取单条卡信息
	alipayinfo(data){
		return request({url: '/manager/alipay/info',method: 'post',data})		
	},
	
	//通道管理 支付 代理银行卡监控 设置标签
	setNormalState(data){
		return request({url: '/manager/alipay/setNormalState',method: 'post',data})		
	},
	//通道管理 支付 代理银行卡监控 设置银行卡信息
	/* 
	 $id = input('card_id');
	 $card_no = input('card_no');//卡号
	 $telephone = input('telephone');//手机号
	 $eve_used_money = input('eve_used_money');//每日收款上限金额
	 $eve_used_num = input('eve_used_num');//每日收款上限笔数
	 $keep_gold = input('keep_gold');//余额留存上限
	 
	 $pay_state = input('pay_state');//充值是否启用 0-是 1-否
	 $repay_state = input('repay_state');//代付是否启用 0-是 1-否
	 */
	alipaysetInfo(data){
		return request({url: '/manager/alipay/setInfo',method: 'post',data})		
	},
	
	//通道管理 支付 代理银行卡监控 离线 1在线  0离线
	alipaysetOnline(data){
		return request({url: '/manager/alipay/setOnline',method: 'post',data})		
	},
	
	//码商 搬砖工数列表
	alipayDelete(data){
		return request({url: '/manager/alipay/delete',method: 'post',data})		
	},
	//支付宝商家配卡池列表
	alipaytradere(data){
		return request({url: '/manager/trader_alipay/trader',method: 'post',data})		
	},
	
	//通道管理 商家综合管理   数据列表
	alipayList(data){
		return request({url: '/manager/trader_alipay/alipayList',method: 'post',data})		
	},
	
	//通道管理 商家综合管理   统计数据
	traderAlipaycountAll(data){
		return request({url: '/manager/trader_alipay/countAll',method: 'post',data})		
	},
	
	//通道管理 商家综合管理   统计数据
	traderalipayCount(data){
		return request({url: '/manager/trader_alipay/alipayCount',method: 'post',data})		
	},
	
	//通道管理 商家综合管理   商户已绑定的支付宝账号
	traderalipay(data){
		return request({url: '/manager/trader_alipay/alipay',method: 'post',data})		
	},
	//未绑定的支付宝
	carderListalipay(data){
		return request({url: '/manager/trader_alipay/carderList',method: 'post',data})		
	},
	//商户加减支付宝账号
	//alipay_id   trader_id    type:IN 加卡    OUT减卡
	bindAlipay(data){
		return request({url: '/manager/trader_alipay/bindAlipay',method: 'post',data})		
	},
	
	// 数字人名币
	
	//支付宝商家配卡池列表
	alipaytradereECny(data){
		return request({url: '/manager/trader_number/trader',method: 'post',data})		
	},
	
	//通道管理 商家综合管理   数据列表
	alipayListECny(data){
		return request({url: '/manager/trader_number/alipayList',method: 'post',data})		
	},
	
	//通道管理 商家综合管理   统计数据
	traderAlipaycountAllECny(data){
		return request({url: '/manager/trader_number/countAll',method: 'post',data})		
	},
	
	//通道管理 商家综合管理   统计数据
	traderalipayCountECny(data){
		return request({url: '/manager/trader_number/alipayCount',method: 'post',data})		
	},
	
	//通道管理 商家综合管理   商户已绑定的支付宝账号
	traderalipayECny(data){
		return request({url: '/manager/trader_number/alipay',method: 'post',data})		
	},
	//未绑定的支付宝
	carderListalipayECny(data){
		return request({url: '/manager/trader_number/carderList',method: 'post',data})		
	},
	//商户加减支付宝账号
	//alipay_id   trader_id    type:IN 加卡    OUT减卡
	bindAlipayECny(data){
		return request({url: '/manager/trader_number/bindAlipay',method: 'post',data})		
	},
	
	
	//四方商户代付订单 订单确认
	  // public function ensureMainTrader()
	  //   {
	  //       $id = input('id');
	ensureMainTrader(data){
		return request({url: '/manager/order/ensureMainTrader',method: 'post',data})		
	},
	
	//通道管理 商家绑店铺 商家列表
	traderStore(data){
		return request({url: '/manager/trader_store/trader',method: 'post',data})		
	},
	//通道管理 商家绑店铺 商家已绑卡列表
	traderStoreStore(data){
		return request({url: '/manager/trader_store/store',method: 'post',data})		
	},
	//通道管理 商家绑店铺  码商列表
	carderListStore(data){
		return request({url: '/manager/trader_store/carderList',method: 'post',data})		
	},
	//通道管理 商家绑店铺  统计
	storeCount(data){
		return request({url: '/manager/trader_store/storeCount',method: 'post',data})		
	},
	//通道管理 商家绑店铺  搬砖工可用店铺
	storeAllList(data){
		return request({url: '/manager/store/allList',method: 'post',data})		
	},
	//通道管理 商家绑店铺  加减店铺
	
	bindStore(data){
		return request({url: '/manager/trader_store/bindStore',method: 'post',data})		
	},
	storeAccount(data){
		return request({url: '/manager/store/store',method: 'post',data})		
	},
	storeSetTraderConfig(data){
		return request({url: '/manager/trader_store/setTraderConfig',method: 'post',data})		
	},
	storeTradercountAll(data){
		return request({url: '/manager/trader_store/countAll',method: 'post',data})		
	},
	// 设置店铺信息
   // public function setInfo()
   //  {
   //      $id = input('id');//店铺ID
   //      $state =  input('state');//状态 0-停用 1-启用
   //      $eve_used_num = input('eve_used_num');//每日收款笔数
   //      $eve_used_money = input('eve_used_money');//每日收款上限
	storeSetInfo(data){
		return request({url: '/manager/store/setInfo',method: 'post',data})		
	},
	deleteStore(data){
		return request({url: '/manager/store/deleteStore',method: 'post',data})		
	},
	
	addOrderAlipay(data){
		return request({url: '/manager/order/addOrderAlipay',method: 'post',data})		
	},
	addOrderAlipayOrNumber(data){
		return request({url: '/manager/order/addOrderAlipayOrNumber',method: 'post',data})		
	},
	/**
	     * 二维码列表(账号管理)
	    public function storeQr()
	    {
	        $carder_id = input('carder_id');//搬砖工ID
	        $store_id = input('store_id');//店铺ID
	        $store_name = input('store_name');//店铺名称
	        $business_no = input('business_no');//商家号
	        $money = input('money');//金额
	        $page = input('page', 1);
	        $size = input('size', Config::get('paginate.list_rows'));
	 */
	storeQr(data){
		return request({url: '/manager/store/storeQr',method: 'post',data})		
	},
	/**
	     * 二维码 停用 启用
	    public function setStoreQrState()
	    {
	        $qr_id = trim(input('qr_id'));//二维码ID
	        $state = input('state');// 0-停用1-启用
	*/
	setStoreQrState(data){
		return request({url: '/manager/store/setStoreQrState',method: 'post',data})		
	},
	/**manager/store/lockedStoreQr   后台锁定的

	     * 锁定/解锁二维码
	     * 单个锁定只传id
	     * 批量锁定 逗号分隔  1,2,3,5,6
	  
	    public function lockedStoreQr()
	    {
	        $qr_id = trim(input('qr_id'));//二维码ID 1,2,3,4,5
	        $is_locked = input('is_locked');//0-解锁 1-锁定

	*/
	lockedStoreQr(data){
		return request({url: '/manager/store/lockedStoreQr',method: 'post',data})		
	},
	/**
	     * 删除二维码
	     * 单个删除只传id
	     * 批量删除 逗号分隔  1,2,3,5,6
	     
	    public function deleteStoreQr()
	    {
	        $qr_id = trim(input('qr_id'));//二维码ID
*/
	deleteStoreQr(data){
		return request({url: '/manager/store/deleteStoreQr',method: 'post',data})		
	},
	/**
	     * 设置支付宝二维码累计失败次数
	    public function setAliFailedNum()
	    {
	        $ali_total_failed_num = input('ali_total_failed_num'); //支付宝二维码累计失败次数
	*/
	setAliFailedNum(data){
		return request({url: '/manager/system/setAliFailedNum',method: 'post',data})		
	},
	/**
	     * 清除累计失败次数

	    public function emptyFailNum()
	    {
	        $qr_id = input('qr_id');//二维码ID (值为空，则清除所有二维码的)
	*/
	emptyFailNum(data){
		return request({url: '/manager/store/emptyFailNum',method: 'post',data})		
	},
	//获取失败次数
	getAliFailedNum(data){
		return request({url: '/manager/system/getAliFailedNum',method: 'post',data})		
	},
   // 审核确认卡商完成的订单.
   subOrderCheck(data){
		return request({url: '/manager/order/subOrderCheck',method: 'post',data})	
   },
   // 审核驳回卡商完成的订单.
   subOrderReject(data){
		return request({url: '/manager/order/subOrderReject',method: 'post',data})	
   },
   // 卡商额度变动记录.
   advanceMoneyList(data){
		return request({url: 'manager/carder/advanceMoneyList',method: 'post',data})	
   },
   // 卡商可提现金额额度变动记录.
   txMoneyList(data){
		return request({url: 'manager/carder/txMoneyList',method: 'post',data})	
   },
   // 卡商可提现金额账变请求.
   setTxMoney(data){
		return request({url: 'manager/carder/setTxMoney',method: 'post',data})	
   },
   // 查看卡不可用原因.
   getDisableReason(data){
		return request({url: 'manager/trader_card/getDisableReason',method: 'post',data})	
   },
   // manager/card/ddMoneyInList 
	// 钉钉账号充值明细
   ddMoneyInList(data){
		return request({url: 'manager/card/ddMoneyInList',method: 'post',data})	
   },
   // manager/order/outTypeList 
	// 代付订单类型查询
   outTypeList(data){
		return request({url: 'manager/order/outTypeList',method: 'post',data})	
   },
   // manager/order/outTypeList 
	// 代付订单类型查询
   setUncertainAmount(data){
		return request({url: 'manager/member/setUncertainAmount',method: 'post',data})	
   },
   // manager/order/checkExcelRepayOrder
	// Excel代付订单查验接口
   checkExcelRepayOrder(data){
		return request({url: 'manager/order/checkExcelRepayOrder',method: 'post',data})	
   },
	manualDisalipayList(data){
		return request({url: '/manager/alipay/manualDisCardList',method: 'post',data})		
	},
	manualDisalipayUnavailableList(data){
		return request({url: '/manager/alipay/manualDisCardUnavailableList',method: 'post',data})		
	},
	choosedDisalipayList(data){
		return request({url: '/manager/alipay/choosedDisCardList',method: 'post',data})		
	},
	disalipayLock(data){
		return request({url: '/manager/alipay/discardLock',method: 'post',data})		
	},
   // /manager/carder/moneyChangeList
	// 搬砖工可用余额账变
   moneyChangeList(data){
		return request({url: 'manager/carder/moneyChangeList',method: 'post',data})	
   },
   // /manager/member/moneyChangeList 
	// 商户余额账变列表
	traderMoneyChangeList(data){
		return request({url: '/manager/member/moneyChangeList',method: 'post',data})		
	},
   // /manager/member/agentMoneyChangeList 
	// 商户余额账变列表
	agentMoneyChangeList(data){
		return request({url: '/manager/member/agentMoneyChangeList',method: 'post',data})		
	},
   // /manager/carder/getTypeDetail 
	// 商户余额账变列表
	getTypeDetail(data){
		return request({url: '/manager/carder/getTypeDetail',method: 'post',data})		
	},
   // /manager/order/ChangeOrderGold 
	// 修改代付订单金额
	ChangeOrderGold(data){
		return request({url: '/manager/order/ChangeOrderGold',method: 'post',data})		
	},
   // /manager/alipay/batchSetInfo 
	// 批量设置账号限额
	batchSetInfo(data){
		return request({url: '/manager/alipay/batchSetInfo',method: 'post',data})		
	},
   // /manager/alipay/batchDelete 
	// 批量删除账号
	batchDelete(data){
		return request({url: '/manager/alipay/batchDelete',method: 'post',data})		
	},
   // /manager/report/carderOut
	// Excel代付导出银行查询
   carderOut(data){
		return request({url: 'manager/report/carderOut',method: 'post',data})	
   },
   // ----------------聚合码------------------
	//聚合码配卡池列表
	alipaytradereJuhe(data){
		return request({url: '/manager/trader_ag_code/trader',method: 'post',data})		
	},
	
	//通道管理 商家综合管理   数据列表
	alipayListJuhe(data){
		return request({url: '/manager/trader_ag_code/alipayList',method: 'post',data})		
	},
	
	//通道管理 商家综合管理   统计数据
	traderAlipaycountAllJuhe(data){
		return request({url: '/manager/trader_ag_code/countAll',method: 'post',data})		
	},
	
	//通道管理 商家综合管理   统计数据
	traderalipayCountJuhe(data){
		return request({url: '/manager/trader_ag_code/alipayCount',method: 'post',data})		
	},
	
	//通道管理 商家综合管理   商户已绑定的聚合码
	traderalipayJuhe(data){
		return request({url: '/manager/trader_ag_code/alipay',method: 'post',data})		
	},
	//未绑定的聚合码
	carderListalipayJuhe(data){
		return request({url: '/manager/trader_ag_code/carderList',method: 'post',data})		
	},
	//商户加减支聚合码
	//alipay_id   trader_id    type:IN 加卡    OUT减卡
	bindAlipayJuhe(data){
		return request({url: '/manager/trader_ag_code/bindAlipay',method: 'post',data})		
	},
	//商户加减支聚合码
	payTypeList(data){
		return request({url: '/manager/alipay/payTypeList',method: 'post',data})		
	},
	
	// /manager/pbm_order/merchantList
		// 话商列表
		billMerchantList(data){
			return request({url: '/manager/pbm_order/merchantList',method: 'post',data})		
		},
	// /manager/pbm_order/allList
		// 话单列表
		billOrderList(data){
			return request({url: '/manager/pbm_order/allList',method: 'post',data})		
		},
	// /manager/pbm_order/stateList
		// 话单状态列表
		billStateList(data){
			return request({url: '/manager/pbm_order/stateList',method: 'post',data})		
		},
	// /manager/pbm_order/notice
		// 话单回调
		billOrderNoitce(data){
			return request({url: '/manager/pbm_order/notice',method: 'post',data})		
		},
	// /manager/pbm_order/countAmount
		// 话单统计
		getTotalMoney(data){
			return request({url: '/manager/pbm_order/countAmount',method: 'post',data})		
		},
	// /manager/pbm_order/setPoolRate
		// 参数: type  WXPAY|ALIPAY  用countAmout的type值
		// parvalue 用countAmout的parvalue值
		// rate  产码值 （整数）
		setPoolRate(data){
			return request({url: '/manager/pbm_order/setPoolRate',method: 'post',data})		
		},
	// /manager/pbm_order/provinceList
		provinceList(data){
			return request({url: '/manager/pbm_order/provinceList',method: 'post',data})		
		},
	// /manager/pbm_order/setProvinceState
		setProvinceState(data){
			return request({url: '/manager/pbm_order/setProvinceState',method: 'post',data})		
		},
	// /manager/system/getPbmConfigInfo
		getPbmConfigInfo(data){
			return request({url: '/manager/system/getPbmConfigInfo',method: 'post',data})		
		},
	// /manager/system/setPbmConfig
		setPbmConfig(data){
			return request({url: '/manager/system/setPbmConfig',method: 'post',data})		
		},
	// /manager/CarderCookie/allList
		cookieList(data){
			return request({url: '/manager/Carder_Cookie_set/allList',method: 'post',data})		
		},
	// /manager/CarderCookie/addInfo
		cookieAdd(data){
			return request({url: '/manager/Carder_Cookie_set/addInfo',method: 'post',data})		
		},
	// /manager/CarderCookie/setInfo
		cookieSet(data){
			return request({url: '/manager/Carder_Cookie_set/setInfo',method: 'post',data})		
		},
	// /manager/CarderCookie/deleteCookie
		deleteCookie(data){
			return request({url: '/manager/Carder_Cookie_set/deleteCookie',method: 'post',data})		
		},
	
}