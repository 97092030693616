import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/main.vue'
// import Main from '../views/cloth/index.vue'
import Login from '../views/login/index.vue'
import Directory from '../views/index.vue' 

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

// replace
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Main',
		component: Main,
		directory:false
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		directory:false,
		meta: {
			noNav:true
		},
	},
	{
		path: '/googleVerification',
		name: 'GoogleVerification',
		component: ()=>import('@/views/login/googleVerification.vue'),
		directory:false,
		meta: {
			noNav:true
		},
	},
	{
		path: '/replace',
		name: 'Replace',
		component: ()=>import('@/views/replace.vue'),
		directory:false,
		meta: {
			noNav:true
		},
	},
	{
		path: '/backReportD',
		name: 'BackReportD',
		component: Directory,
		meta: {
			id:1,
			title: '系统报表',
			allShow:false,
			leven:0,
			parentId:0,
			islink:false
		},
		directory:true
	},
	{
		path: '/backReport',
		name: 'BackReport',
		component: () => import('@/views/report/backReport.vue'),
		meta: {
			id:2,
			title: '后台报表管理',
			allShow:false,
			leven:1,
			parentId:1,
			islink:true
		},
		directory:true
	},
	
	{
		path: '/paymentTypeD',
		name: 'PaymentTypeD',
		component: Directory,
		meta: {
			id:3,
			title: '通道管理',
			allShow:false,
			leven:0,
			parentId:0,			
			islink:false
		},
		directory:true
	},
	{
		path: '/hdTimeSet',
		name: 'HdTimeSet',
		component: () => import('@/views/passagewayManage/hdTimeSet.vue'),
		meta: {
			id:63,
			title: '话单时间配置',
			allShow:false,
			leven:1,
			parentId:3,
			islink:true
		},
		directory:true
	},
	{
		path: '/CarderCookie',
		name: 'CarderCookie',
		component: () => import('@/views/passagewayManage/CarderCookie.vue'),
		meta: {
			id:64,
			title: 'Cookie配置',
			allShow:false,
			leven:1,
			parentId:3,
			islink:true
		},
		directory:true
	},
	{
		path: '/paymentType',
		name: 'PaymentType',
		component: () => import('@/views/passagewayManage/paymentType.vue'),
		meta: {
			id:4,
			title: '支付类型管理',
			allShow:false,
			leven:1,
			parentId:3,
			islink:true
		},
		directory:true
	},
	{
		path: '/bankCardalipayAccount',
		name: 'BankCardalipayAccount',
		component: () => import('@/views/passagewayManage/alipayAccount.vue'),
		meta: {
			id:5,
			title: '账号管理',
			allShow:false,
			leven:1,
			parentId:3,
			islink:true
		},
		directory:true
	},
	
	{
		path: '/agenIncomeReport',
		name: 'AgenIncomeReport',
		component: () => import('@/views/report/agenIncomeReport.vue'),		
		meta: {
			id:7,
			title: '代理进账报表',
			allShow:false,
			leven:1,
			parentId:1,
			islink:true
		},
		directory:true
	},
	// {
	// 	path: '/bankCard',
	// 	name: 'JixiangPaybank',
	// 	component: () => import('@/views/passagewayManage/jixiangPay/JixiangPaybank.vue'),		
	// 	meta: {
	// 		id:8,
	// 		title: '代理银行卡监控',
	// 		allShow:false,
	// 		leven:2,
	// 		parentId:5,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	// {
	// 	path: '/bankCardDifference',
	// 	name: 'JixiangPayDifference',
	// 	component: () => import('@/views/passagewayManage/difference.vue'),
	// 	meta: {
	// 		id:6,
	// 		title: '卡差值表',
	// 		allShow:false,
	// 		leven:2,
	// 		parentId:5,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	{
		path: '/merchantIncomeReport',
		name: 'MerchantIncomeReport',
		component: () => import('@/views/report/merchantIncomeReport.vue'),
		meta: {
			id:9,
			title: '商家进账报表',
			allShow:false,
			leven:1,
			parentId:1,
			islink:true
		},
		directory:true
	},
	{
		path: '/merchantRateReport',
		name: 'MerchantRateReport',
		component: () => import('@/views/report/merchantRateReport.vue'),
		meta: {
			id:18,
			title: '商家费率报表',
			allShow:false,
			leven:1,
			parentId:1,
			islink:true
		},
		directory:true
	},
	{
		path: '/accountManagerD',
		name: 'AccountManagerD',
		component: Directory,
		meta: {
			id:10,
			title: '商户管理',
			allShow:false,
			leven:0,
			parentId:0,
			islink:false
		},
		directory:true
	},
	{
		path: '/agentAccount',
		name: 'AgentAccount',
		component: () => import('@/views/account/agentAccount/agentAccount'),
		meta: {
			id:11,
			title: '代理管理',
			allShow:false,
			leven:1,
			parentId:10,
			islink:true
		},
		directory:true
	},
	{
		path: '/traderAccount',
		name: 'TraderAccount',
		component: () => import('@/views/account/traderAccount/traderAccount'),
		meta: {
			id:12,
			title: '商家管理',
			allShow:false,
			leven:1,
			parentId:10,
			islink:true
		},
		directory:true
	},
	{
		path: '/orderD',
		name: 'OrderD',
		component: Directory,
		meta: {
			id:13,
			title: '订单管理',
			allShow:false,
			leven:0,
			parentId:0,			
			islink:false
		},
		directory:true
	},
	{
		path: '/coderManagementD',
		name: 'CoderManagementD',
		component: Directory,
		meta: {
			id:14,
			title: '搬砖工管理',
			allShow:false,
			leven:0,
			parentId:0,			
			islink:false
		},
		directory:true
	},
	{
		path: '/systemD',
		name: 'SystemD',
		component: Directory,
		meta: {
			id:15,
			title: '系统设置',
			allShow:false,
			leven:0,
			parentId:0,
			islink:false
		},
		directory:true
	},
	{
		path: '/coderManagement',
		name: 'CoderManagement',
		component: () => import('@/views/coderManagement'),	
		meta: {
			id:16,
			title: '搬砖工',
			allShow:false,
			leven:1,
			parentId:14,
			islink:true
		},
		directory:true
	},
	{
		path: '/carderChangeReport',
		name: 'CarderChangeReport',
		component: () => import('@/views/coderManagement/carderChangeReport.vue'),	
		meta: {
			id:56,
			title: '搬砖工账变',
			allShow:false,
			leven:1,
			parentId:14,
			islink:true
		},
		directory:true
	},
	{
		path: '/passagewayReport',
		name: 'PassagewayReport',
		component: () => import('@/views/report/passagewayReport.vue'),		
		meta: {
			id:17,
			title: '通道报表',
			allShow:false,
			leven:1,
			parentId:1,
			islink:true
		},
		directory:true
	},
	{
		path: '/order',
		name: 'Order',
		component: () => import('@/views/order'),
		meta: {
			id:19,
			title: '交易订单',
			allShow:false,
			leven:1,
			parentId:13,			
			islink:true
		},
		directory:true
	},
	{
		path: '/billOrder',
		name: 'BillOrder',
		component: () => import('@/views/order/billOrder.vue'),
		meta: {
			id:62,
			title: '话单列表',
			allShow:false,
			leven:1,
			parentId:13,			
			islink:true
		},
		directory:true
	},
	// {
	// 	path: '/orderInfo',
	// 	name: 'OrderInfo',
	// 	component: () => import('@/views/order/orderInfo/index'),
	// 	meta: {
	// 		id:20,
	// 		title: '操作',
	// 		allShow:false,
	// 		leven:2,
	// 		parentId:19,			
	// 		islink:true
	// 	},
	// 	directory:false
	// },
	// {
	// 	path: '/TraterbankAlipay',
	// 	name: 'TraterbankAlipay',
	// 	component: () => import('@/views/passagewayManage/alipayPool.vue'),
	// 	meta: {
	// 		id:21,
	// 		title: '商家账号配置',
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:3,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	{
		path: '/TraterbankAlipay',
		name: 'TraterbankAlipay',
		component: () => import('@/views/passagewayManage/alipayPool.vue'),
		meta: {
			id:44,
			title: '支付宝配置',
			allShow:false,
			leven:2,
			parentId:21,
			islink:true
		},
		directory:true
	},
	{
		path: '/juhePool',
		name: 'JuhePool',
		component: () => import('@/views/passagewayManage/juhePool.vue'),
		meta: {
			id:59,
			title: '聚合码配置',
			allShow:false,
			leven:2,
			parentId:21,
			islink:true
		},
		directory:true
	},
	{
		path: '/eCnyPool',
		name: 'eCnyPool',
		component: () => import('@/views/passagewayManage/eCnyPool.vue'),
		meta: {
			id:52,
			title: '数字人民币配置',
			allShow:false,
			leven:2,
			parentId:21,
			islink:true
		},
		directory:true
	},
	// {
	// 	path: '/traterbank',
	// 	name: 'TraterbankT',
	// 	component: () => import('@/views/passagewayManage/traterbank.vue'),
	// 	meta: {
	// 		id:43,
	// 		title: '商家卡池配置',
	// 		allShow:false,
	// 		leven:2,
	// 		parentId:21,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	{
		path: '/traterbanksub',
		name: 'Traterbanksub',
		component: () => import('@/views/passagewayManage/traterBank/traterbankAdd.vue'),
		meta: {
			id:22,
			title: '',
			allShow:false,
			leven:1,
			parentId:3,
			islink:false
		},
		directory:false
	},
	// {
	// 	path: '/traterCompositealipay',
	// 	name: 'TraterCompositealipay',
	// 	component: () => import('@/views/passagewayManage/alipayComposite.vue'),
	// 	meta: {
	// 		id:23,
	// 		title: '商家综合管理',
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:3,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	// {
	// 	path: '/cardProcess',
	// 	name: 'CardProcess',
	// 	component: () => import('@/views/passagewayManage/cardProcess.vue'),
	// 	meta: {
	// 		id:24,
	// 		title: '卡账号处理',
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:3,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	// {
	// 	path: '/bankOpenSet',
	// 	name: 'BankOpenSet',
	// 	component: () => import('@/views/passagewayManage/bankOpenSet.vue'),
	// 	meta: {
	// 		id:25,
	// 		title: '银行设置管理',
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:3,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	{
		path: '/dorderPay',
		name: 'OrderPay',
		component: () => import('@/views/order/orderPay'),
		meta: {
			id:26,
			title: '代付订单',
			allShow:false,
			leven:1,
			parentId:13,			
			islink:true
		},
		directory:true
	},
	{
		path: '/ownOrderPay',
		name: 'OwnOrderPay',
		component: () => import('@/views/order/ownOrderPay'),
		meta: {
			id:41,
			title: '手动代付订单',
			allShow:false,
			leven:1,
			parentId:13,			
			islink:true
		},
		directory:true
	},
	{
		path: '/cnyOrderPay',
		name: 'CnyOrderPay',
		component: () => import('@/views/order/cnyOrderPay'),
		meta: {
			id:55,
			title: '钱包代付订单',
			allShow:false,
			leven:1,
			parentId:13,			
			islink:true
		},
		directory:true
	},
	{
		path: '/excelOrderPay',
		name: 'excelOrderPay',
		component: () => import('@/views/order/excelOrderPay'),
		meta: {
			id:50,
			title: 'Excel代付订单',
			allShow:false,
			leven:1,
			parentId:13,			
			islink:true
		},
		directory:true
	},
	{
		path: '/merchantOutReport',
		name: 'MerchantOutReport',
		component: () => import('@/views/report/merchantOutReport.vue'),
		meta: {
			id:27,
			title: '商家出账报表',
			allShow:false,
			leven:1,
			parentId:1,
			islink:true
		},
		directory:true
	},
	// {
	// 	path: '/withdraw',
	// 	name: 'Withdraw',
	// 	component: () => import('@/views/order/withdraw'),
	// 	meta: {
	// 		id:28,
	// 		title: '搬砖工提润订单',
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:13,			
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	// {
	// 	path: '/tirunOrder',
	// 	name: 'TirunOrder',
	// 	component: () => import('@/views/order/tirunOrder'),
	// 	meta: {
	// 		id:36,
	// 		title: '平台提润订单',
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:13,			
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	// {
	// 	path: '/dailiTirunOrder',
	// 	name: 'DailiTirunOrder',
	// 	component: () => import('@/views/order/dailiTirunOrder'),
	// 	meta: {
	// 		id:36,
	// 		title: '代理提润订单',
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:13,			
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	// {
	// 	path: '/forTirunOrder',
	// 	name: 'ForTirunOrder',
	// 	component: () => import('@/views/order/forTirunOrder'),
	// 	meta: {
	// 		id:36,
	// 		title: '四方提润订单',
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:13,			
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	// {
	// 	path: '/taobaoOrderPay',
	// 	name: 'TaobaoOrderPay',
	// 	component: () => import('@/views/order/taobaoOrder'),
	// 	meta: {
	// 		id:48,
	// 		title: '四方商户代付',
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:13,			
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	{
		path: '/transactionNot',
		name: 'TransactionNot',
		component: () => import('@/views/order/transactionNot'),
		meta: {
			id:29,
			title: '交易通知',
			allShow:false,
			leven:1,
			parentId:13,			
			islink:true
		},
		directory:true
	},
	{
		path: '/morderPayNot',
		name: 'OrderPayNot',
		component: () => import('@/views/order/orderPayNot'),
		meta: {
			id:30,
			title: '代付通知',
			allShow:false,
			leven:1,
			parentId:13,			
			islink:true
		},
		directory:true
	},
	{
		path: '/replenishment',
		name: 'Replenishment',
		component: () => import('@/views/order/replenishment'),
		meta: {
			id:31,
			title: '补增订单',
			allShow:false,
			leven:1,
			parentId:13,			
			islink:true
		},
		directory:true
	},{
		path: '/updatePassword',
		name: 'updatePassword',
		component: () => import('@/views/system/index'),
		meta: {
			id:32,
			title: '修改密码',
			allShow:false,
			leven:1,
			parentId:15,			
			islink:true
		},
		directory:true
	},
	{
		path: '/cancelOrder',
		name: 'CancelOrder',
		component: () => import('@/views/order/cancelOrder'),
		meta: {
			id:33,
			title: '撤销订单',
			allShow:false,
			leven:1,
			parentId:13,			
			islink:true
		},
		directory:true
	},
	{
		path: '/scoreOrder',
		name: 'ScoreOrder',
		component: () => import('@/views/order/scoreOrder'),
		meta: {
			id:34,
			title: '补单记录',
			allShow:false,
			leven:1,
			parentId:13,			
			islink:true
		},
		directory:true
	},
	// {
	// 	path: '/bankCardMonitorFailure',
	// 	name: 'JixiangPayMonitorFailure',
	// 	component: () => import('@/views/passagewayManage/monitorFailure.vue'),
	// 	meta: {
	// 		id:35,
	// 		title: '统计监控失败原因',
	// 		allShow:false,
	// 		leven:2,
	// 		parentId:5,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	{
		path: '/Tirun',
		name: 'Tirun',
		component: () => import('@/views/order/tirun'),
		meta: {
			id:37,
			title: '平台提润',
			allShow:false,
			leven:1,
			parentId:13,			
			islink:true
		},
		directory:false
	},
	{
		path: '/coderReport',
		name: 'CoderReport',
		component: () => import('@/views/report/coderReport.vue'),
		meta: {
			id:38,
			title: '搬砖工进账报表',
			allShow:false,
			leven:1,
			parentId:1,
			islink:true
		},
		directory:true
	},
	{
		path: '/carderOut',
		name: 'CarderOut',
		component: () => import('@/views/report/carderOutReport.vue'),
		meta: {
			id:57,
			title: '搬砖工出账报表',
			allShow:false,
			leven:1,
			parentId:1,
			islink:true
		},
		directory:true
	},
	{
		path: '/userSys',
		name: 'UserSys',
		component: () => import('@/views/system/userSys.vue'),
		meta: {
			id:39,
			title: '用户管理',
			allShow:false,
			leven:1,
			parentId:15,
			islink:true
		},
		directory:true
	},
	{
		path: '/characterSys',
		name: 'CharacterSys',
		component: () => import('@/views/system/characterSys.vue'),
		meta: {
			id:40,
			title: '角色管理',
			allShow:false,
			leven:1,
			parentId:15,
			islink:true
		},
		directory:true
	},
	// {
	// 	path: '/bankCardLoss',
	// 	name: 'BankCardLoss',
	// 	component: () => import('@/views/passagewayManage/bankCardLoss.vue'),
	// 	meta: {
	// 		id:41,
	// 		title: '银行卡报损报表',
	// 		allShow:false,
	// 		leven:2,
	// 		parentId:5,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	// {
	// 	path: '/dingDingLog',
	// 	name: 'dingDingLog',
	// 	component: () => import('@/views/passagewayManage/dingDingLog.vue'),
	// 	meta: {
	// 		id:49,
	// 		title: '钉钉划转充值记录',
	// 		allShow:false,
	// 		leven:2,
	// 		parentId:5,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	{
		path: '/bankCardalipayAccount',
		name: 'BankCardalipayAccount',
		component: () => import('@/views/passagewayManage/alipayAccount.vue'),
		meta: {
			id:42,
			title: '支付宝',
			allShow:false,
			leven:2,
			parentId:5,
			islink:true
		},
		directory:true
	},
	{
		path: '/juheAccount',
		name: 'JuheAccount',
		component: () => import('@/views/passagewayManage/juheAccount.vue'),
		meta: {
			id:58,
			title: '聚合码',
			allShow:false,
			leven:2,
			parentId:5,
			islink:true
		},
		directory:true
	},
	
	{
		path: '/eCnyAccount',
		name: 'eCnyAccount',
		component: () => import('@/views/passagewayManage/eCnyAccount.vue'),
		meta: {
			id:51,
			title: '数字人民币',
			allShow:false,
			leven:2,
			parentId:5,
			islink:true
		},
		directory:true
	},
	
	// {
	// 	path: '/traterComposite',
	// 	name: 'TraterComposite',
	// 	component: () => import('@/views/passagewayManage/traterComposite.vue'),
	// 	meta: {
	// 		id:46,
	// 		title: '商家综合管理',
	// 		allShow:false,
	// 		leven:2,
	// 		parentId:23,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	{
		path: '/traterCompositealipay',
		name: 'TraterCompositealipay',
		component: () => import('@/views/passagewayManage/alipayComposite.vue'),
		meta: {
			id:45,
			title: '支付宝管理',
			allShow:false,
			leven:2,
			parentId:23,
			islink:true
		},
		directory:true
	},
	{
		path: '/juheComposite',
		name: 'JuheComposite',
		component: () => import('@/views/passagewayManage/juheComposite.vue'),
		meta: {
			id:61,
			title: '聚合码管理',
			allShow:false,
			leven:2,
			parentId:23,
			islink:true
		},
		directory:true
	},
	{
		path: '/traterCompositeECny',
		name: 'traterCompositeECny',
		component: () => import('@/views/passagewayManage/eCnyTraterComposite.vue'),
		meta: {
			id:53,
			title: '数字人民币管理',
			allShow:false,
			leven:2,
			parentId:23,
			islink:true
		},
		directory:true
	},
	{
		path: '/traterbankalipaysub',
		name: 'Traterbankalipaysub',
		component: () => import('@/views/passagewayManage/traterBank/trateralipayAdd.vue'),
		meta: {
			id:47,
			title: '',
			allShow:false,
			leven:1,
			parentId:44,
			islink:false
		},
		directory:false
	},
	{
		path: '/eCnyTraterAdd',
		name: 'eCnyTraterAdd',
		component: () => import('@/views/passagewayManage/traterBank/eCnyTraterAdd.vue'),
		meta: {
			id:54,
			title: '',
			allShow:false,
			leven:1,
			parentId:44,
			islink:false
		},
		directory:false
	},
	{
		path: '/juheTraderAdd',
		name: 'JuheTraderAdd',
		component: () => import('@/views/passagewayManage/traterBank/juheTraderAdd.vue'),
		meta: {
			id:60,
			title: '',
			allShow:false,
			leven:1,
			parentId:44,
			islink:false
		},
		directory:false
	},
	
	{
		path: '/index',
		name: 'Index',
		component: () => import('@/views/index.vue'),
		meta: {
			id:10,
			title: '数据总览',
			allShow:false,
			leven:0,
			parentId:0,
			islink:true
		},
		directory:false
	},
	//最大id  48
	/* {
		path: '/traWhitelist',
		name: 'TraWhitelist',
		component: () => import('@/views/system/traWhitelist'),
		meta: {
			id:35,
			title: '商家白名单',
			allShow:false,
			leven:1,
			parentId:15,			
			islink:true
		},
		directory:true
	}, */
]

const router = new VueRouter({
	linkActiveClass: "activeClass",
	/*添加默认样式 activeClass   activeClass 写在组件样式里面   */
	routes
})

export default router
